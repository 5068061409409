import React from 'react';
import Header from '../Header/Header';
import './Hero.css' ;
import hero_image_back  from "../../assets/hero_image_back.png";
import Hero_image from  "../../assets/hero_image.png";
import Heart from "../../assets/heart.png";
import Calories from  "../../assets/calories.png";
import {motion} from "framer-motion";
import NumberCounter from 'number-counter';

const Hero = () => {

  const transition={type: 'spring' , duration: 3};
  const mobile=window.innerWidth<=768 ? true :false;
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
<div className="left-h">

<Header/>

<div className="the-best-ad">
  
  <motion.div
  initial={{left: mobile ? "165px" : "238px"}}
  whileInView={{left:'8px'}}
  transition= {{...transition , type:'tween'}}
  
  
  ></motion.div>
  <span>The best fitness club in the town</span>
</div>

<div className="hero-text">
  <div><span className='stroke-text'>Shape </span>
  <span>Your</span>
  </div>

  <div>
  <span> ideal body</span>
  </div>

  <div>
    <span>Lorem ipsum dolor sit amet, consectetur adipisicing
     elit. Sint ratione excepturi voluptate doloremque maxime hic temporibus eum sapiente reiciendis nostrum.</span>
  </div>

  </div>

<div className="figures">
  <div>
    <span>
      <NumberCounter end={140} start={100} delay='4' preFix="+"/>
    </span>
    <span>Expert Coaches</span>
  </div>

  <div>
    <span>
    <NumberCounter end={978} start={800} delay='4' preFix="+"/>
    </span>
    <span>Members joined</span>
  </div>

  <div>
    <span>
    <NumberCounter end={50} start={30} delay='4' preFix="+"/>
    </span>
    <span>Fitness programs</span>
  </div>
</div>

<div className="hero-button">

  <button className="btn">get started</button>
  <button className="btn">learn more</button>
</div>

</div>
<div className="right-h">

<button className="btn">Join Now</button>

<motion.div 
initial={{right:"-1rem"}}
whileInView={{right:"4rem"}}
transition={{transition}}
className="heart-rate">
  
  <img src={Heart} alt="" />
  <span>Heart Rate</span><span>116 bpm</span>
</motion.div>

<img src={Hero_image} alt="" className="hero-image" />
<motion.img
initial={{right:"11rem"}}
whileInView={{right:"20rem"}}
transition={{transition}}
src={hero_image_back} alt="" className="hero-image-back" />

<motion.div
initial={{right:"37rem"}}
whileInView={{right:"28rem"}}
transition={{transition}}
className="calories">
  <img src={Calories} alt="" />
  <div>
  <span>Calories burned</span><span>220kcal</span>
</div>
</motion.div>
</div>

    </div>
  )
}

export default Hero